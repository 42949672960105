import React, {useEffect, useState} from "react"
import {navigate} from "gatsby"
import classNames from "classnames";
import PageTitle from "../PageTitle";
import _ from "lodash";
import DateText from "../DateText";
import NewsCategoryLabel from "../NewsCategoryLabel"
import Button from "../Button"

export default ({item, loading, canRead}) => {
  const [tags, setTags] = useState([])
  useEffect(() => {
    if (item !== null && item.tags !== undefined && item.tags !== null) {
      const tags = item.tags.split(',')
      setTags(tags)  
    }
  }, [item])
  return (
    <div>
      <PageTitle style={{padding: "0"}}>NEWS</PageTitle>
      <div className={classNames({loading: loading}, "container", "bg-white", "inner")}>
        {!_.isEmpty(item) ? (
          <>
            <span className="date"><DateText text={item.stt_ts}/></span>
            <NewsCategoryLabel dsptgt_tc={item.dsptgt_tc} />
            <h2>{item.title}</h2>
            <div className="tag-list-detail">
              {tags.map((value, index) => {
                return <a href={`/news/?tag=${encodeURIComponent(value)}`} key={value}><span>#{value}</span></a>
              })}
            </div>
            {(canRead) ? (
              <div className="editor" dangerouslySetInnerHTML={{__html: item.article}}/>
            ) : (
              <div className="not-covered">
                <p>このニュース記事を閲覧するファンクラブ権限がございません。</p>
                <p>確認する場合は以下サイトより<br/>ファンクラブサービスへの加入をお願いいたいます。</p>
                <br/>
                <Button type={"button"} attr={{
                  onClick: () => navigate(process.env.FANCLUB_URL + 'email_validation.php')
                }}>加入サービスへ</Button>
              </div>
            )}
          </>
        ) : (
          item == null && (
            <div className={classNames("container", "bg-white", "inner")}>
              <p style={{textAlign: "center"}}>ニュースはございません。</p>
            </div>
          )
        )}
      </div>
    </div>
  )
}
